/* custom scrollbar */
::-webkit-scrollbar {
  width: 16px;
  background-color: transparent;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #b7b7b7;
  border-radius: 16px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #8c8c8c;
}

*::-webkit-scrollbar-corner {
  background: transparent;
}

body {
  user-select: none;
}

@media screen and (max-width: 768px) {
  ::-webkit-scrollbar {
    display: none;
  }
}

@font-face {
  font-family: Normal;
  src: url('../assets/fonts/WorkSans-Regular.ttf');
}

@font-face {
  font-family: Semi;
  src: url('../assets/fonts/WorkSans-SemiBold.ttf');
}

@font-face {
  font-family: Medium;
  src: url('../assets/fonts/WorkSans-Medium.ttf');
}

@font-face {
  font-family: Bold;
  src: url('../assets/fonts/WorkSans-Bold.ttf');
}

@font-face {
  font-family: Metal;
  src: url('../assets/fonts/Metal.ttf');
}
